<template>
    <div>
       <b-row>
        <b-col md="7">
         <b-card> <tabel></tabel></b-card>
        </b-col>
        <b-col md="5">
         <b-card>
          <form-add></form-add>
         </b-card>
        </b-col>
       </b-row>
    </div>
  </template>
  
  <script>
  import tabel from './component/tabel.vue';
  import formAdd from  './component/form.vue'
    import {
      BRow,
      BCol,BCard
    } from 'bootstrap-vue'
  
    export default {
      components: {
        BRow,
        BCol,
        tabel,
        formAdd,
        BCard
  
      }
  
    }
  </script>
  
  <style>
  
  </style>