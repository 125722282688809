<template>
    <!-- form -->
    <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <b-col md="12">
                    <h5>
                        <feather-icon icon='PlusIcon' size="15"></feather-icon> Periode Pendaftaran
                    </h5>
                </b-col>
                <b-col md="12">
                    <b-form-group label="Judul">
                        <validation-provider #default="{ errors }" name="First Name" rules="required">
                            <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                                placeholder="First Name" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group label="Tanggal Mulai">
                        <validation-provider #default="{ errors }" name="Keterangan" rules="required">
                            <b-form-datepicker id="example-tgl_mulai" v-model="input.tgl_mulai" class="mb-1" 
                            :state="errors.length > 0 ? false:null"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group label="Tanggal Selesai">
                        <validation-provider #default="{ errors }" name="Keterangan" rules="required">
                            <b-form-datepicker id="example-tgl_selesai" v-model="input.tgl_selesai" class="mb-1" 
                            :state="errors.length > 0 ? false:null"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group label="Keterangan">
                        <validation-provider #default="{ errors }" name="Keterangan" rules="required">
                            <b-form-textarea id="textarea-default" placeholder="Textarea" rows="3"
                                :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col md="12">
                    <b-form-group label="Kategori Pelopor Keselamatan">
                        <validation-provider #default="{ errors }" name="Keterangan" rules="required">
                            <b-form-select v-model="selected" :options="options"
                                :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-button variant="primary" type="submit" @click.prevent="validationForm" size="sm">
                        <feather-icon icon='SaveIcon' size="15"></feather-icon> Submit
                    </b-button>
                    <b-button variant="warning" type="submit" size="sm" class=" ml-1">
                        <feather-icon icon='XCircleIcon' size="15"></feather-icon> Batal
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
</template>

<script>
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BFormSelect, BFormDatepicker
    } from 'bootstrap-vue'
    import {
        required,
        email
    } from '@validations'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BCardText,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BButton,
            FeatherIcon,
            BFormTextarea,
            BFormSelect,BFormDatepicker
        },
        data() {
            return {
                input : {},
                emailValue: '',
                name: '',
                required,
                email,
                options: [{
                        value: null,
                        text: 'Please select an option'
                    },
                    {
                        value: 'a',
                        text: 'This is First option'
                    },
                ],
            }
        },
        methods: {
            
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        // eslint-disable-next-line
                        alert('form submitted!')
                    }
                })
            },
        },
    }
</script>