var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h5',[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"15"}}),_vm._v(" Periode Pendaftaran ")],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Judul"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"First Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal Mulai"}},[_c('validation-provider',{attrs:{"name":"Keterangan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-tgl_mulai","state":errors.length > 0 ? false:null},model:{value:(_vm.input.tgl_mulai),callback:function ($$v) {_vm.$set(_vm.input, "tgl_mulai", $$v)},expression:"input.tgl_mulai"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal Selesai"}},[_c('validation-provider',{attrs:{"name":"Keterangan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-tgl_selesai","state":errors.length > 0 ? false:null},model:{value:(_vm.input.tgl_selesai),callback:function ($$v) {_vm.$set(_vm.input, "tgl_selesai", $$v)},expression:"input.tgl_selesai"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Keterangan"}},[_c('validation-provider',{attrs:{"name":"Keterangan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Textarea","rows":"3","state":errors.length > 0 ? false:null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Kategori Pelopor Keselamatan"}},[_c('validation-provider',{attrs:{"name":"Keterangan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options,"state":errors.length > 0 ? false:null},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon","size":"15"}}),_vm._v(" Submit ")],1),_c('b-button',{staticClass:" ml-1",attrs:{"variant":"warning","type":"submit","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"15"}}),_vm._v(" Batal ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }